import { useEffect, useRef, useState } from "react";
import BannerItem from "./BannerItem";
import { BannerMenu, BannerMenuPoint, BannersWrapper } from "./styled";
import { isMobile } from "react-device-detect";

const Banner = ({ isMenuActive, movies }) => {
  const scrollTimeout = useRef(null);
  const timerBanner = useRef(null);
  const bannerWrapperRef = useRef(null);
  const bannerMenuRef = useRef(null);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  const handleScroll = (event) => {
    clearTimeout(scrollTimeout.current); //clear previous timeout
    const evt = event;
    scrollTimeout.current = setTimeout(() => {
      setCurrentBannerIndex(
        Math.round(evt.target.scrollLeft / evt.target.clientWidth)
      );
    }, 100);
  };

  const nextBanner = () => {
    // console.log("next banner triggered", movies.length);
    if (currentBannerIndex < movies.length - 1) {
      setCurrentBannerIndex((prev) => prev + 1);
    }
  };

  const handleResize = () => {
    if (bannerWrapperRef.current) {
      if (bannerMenuRef.current) {
        bannerMenuRef.current.style.top = `${
          !isMobile
            ? bannerWrapperRef.current.clientHeight -
              bannerMenuRef.current.clientHeight * 1.5
            : bannerWrapperRef.current.clientHeight -
              bannerMenuRef.current.clientHeight * 1
        }px`;
      }
    }
  };

  useEffect(() => {
    if (bannerWrapperRef.current) {
      bannerWrapperRef.current.scrollLeft =
        window.innerWidth * currentBannerIndex;
      handleResize();
    }
    if (currentBannerIndex === movies.length) {
      setCurrentBannerIndex(0);
    }
  }, [currentBannerIndex]);

  useEffect(() => {
    // console.log("timer banner started");
    timerBanner.current = setInterval(() => nextBanner(), 10000);

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(timerBanner.current);
      // console.log("timer banner stoped");
    };
  }, []);

  return (
    <>
      <BannersWrapper
        ref={bannerWrapperRef}
        className={isMobile ? "mobile" : ""}
        onScroll={handleScroll}
      >
        {movies.map((movie, index) => (
          <BannerItem
            key={index}
            title={movie.title}
            subtitle={movie.subtitle}
            friendly_url={movie.friendly_url}
            banner_desktop={movie.banner_desktop}
            banner_mobile={movie.banner_mobile}
            release_date={movie.release_date}
            out_date={movie.out_date}
            youtube_id={movie.youtube_id}
          />
        ))}
      </BannersWrapper>

      <BannerMenu
        ref={bannerMenuRef}
        className={`${isMenuActive ? "off" : ""} ${isMobile ? "mobile" : ""}`}
      >
        {movies.map((movie, index) => (
          <BannerMenuPoint
            key={index}
            className={index === currentBannerIndex ? "selected" : ""}
            onClick={() => {
              setCurrentBannerIndex(index);
              clearInterval(timerBanner.current);
              timerBanner.current = setInterval(() => nextBanner(), 10000);
            }}
          />
        ))}
      </BannerMenu>
    </>
  );
};

export default Banner;

import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import {
  getFilmesEmBreve,
  getFilmesEmCartaz,
  getHomeContent,
} from "../../services/api";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";
import ListMovies from "../../components/ListMovies";
import { MainWrapper } from "./styled";
import { isMobile } from "react-device-detect";

const Home = ({ isMenuActive, setIsMenuActive }) => {
  const [homeContent, setHomeContent] = useState(null);
  const homeContentRef = useRef(false);

  useEffect(() => {
    if (homeContent && !homeContentRef.current) {
      homeContentRef.current = true;
    }
  }, [homeContent]);

  useEffect(() => {
    getHomeContent().then((res) => {
      setHomeContent(res.data);
    });
  }, []);

  return (
    <>
      <Header setIsMenuActive={setIsMenuActive} />
      {homeContent && (
        <Banner isMenuActive={isMenuActive} movies={homeContent.banner} />
      )}
      <MainWrapper className={isMobile ? "mobile" : ""}>
        {homeContent && (
          <>
            <ListMovies
              movies={homeContent.in_theaters}
              wrapperTitle={"Em Cartaz"}
            />
            <ListMovies
              movies={homeContent.coming_soon}
              wrapperTitle={"Em Breve nos Cinemas"}
            />
          </>
        )}
      </MainWrapper>
      <Footer />
    </>
  );
};

export default Home;

import styled from "styled-components";

export const ListMoviesWrapper = styled.section`
  pointer-events: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  z-index: 0;
  padding: 0px 0px 40px;
  @media only screen and (max-width: 1200px) {
    padding: 20px 31px;
    justify-content: flex-start;
  }
`;

export const ListMoviesWrapperTitle = styled.h1`
  display: flex;
  width: 100%;
  font: ${(props) => props.theme.font.titulo};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 20px;
  @media only screen and (max-width: 1200px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const ListMoviesItemWrapper = styled.button`
  cursor: pointer;
  pointer-events: all;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 220px;
  margin: 10px 20px 10px 0px;
  transform: scale(1);
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.05);
    opacity: 0.7;
  }

  @media only screen and (max-width: 1200px) {
    width: 200px;
    max-width: 46%;
    margin: 10px 5px;
  }
`;

export const ListMoviesItemPoster = styled.div`
  pointer-events: none;
  display: flex;
  flex-flow: row wrap;
  align-items: start;
  justify-content: center;
  width: 220px;
  height: 330px;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.primary};

  @media only screen and (max-width: 1200px) {
    width: 100%;
    height: auto;
  }

  img {
    pointer-events: none;
    user-select: none;
    z-index: 0;
    width: 100%;
    height: 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    object-fit: cover;
  }

  @media only screen and (max-width: 1200px) {
  }
`;

export const ListMoviesInfos = styled.div`
  pointer-events: none;

  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 1200px) {
  }
`;

export const ListMoviesTitle = styled.h1`
  pointer-events: none;

  width: 100%;
  font: ${(props) => props.theme.font.tituloThumb};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-top: 12px;
  margin-bottom: 1px;
  @media only screen and (max-width: 1200px) {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
`;
export const ListMoviesSubtitle = styled.h2`
  pointer-events: none;
  width: 100%;
  font: ${(props) => props.theme.font.subtituloThumb};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 5px;
  @media only screen and (max-width: 1200px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`;

export const ListMoviesDate = styled.h2`
  pointer-events: none;
  width: 100%;
  font: ${(props) => props.theme.font.tituloThumb};
  color: ${(props) => props.theme.color.primary};
  filter: brightness(10);
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 0px;
  @media only screen and (max-width: 1200px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`;

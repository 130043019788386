import { useState } from "react";
import Lottie from "react-lottie";
import { Burger, Head, Logo, Nav, Offcanvas } from "./styled";
import * as animationData from "../../assets/lottie/33460-burger-menu.json";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Header = ({ setIsMenuActive }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [speed, setSpeed] = useState(-3);

  const toogleMenu = () => {
    console.log("clicou");
    let showMenuTemp = !showMenu;
    setShowMenu(showMenuTemp);
    if (showMenuTemp) {
      setSpeed(2);
      setIsMenuActive(true);
    } else {
      setSpeed(-3);
      setIsMenuActive(false);
    }
  };

  const goHome = (e) => {
    e.preventDefault();
    if (e.ctrlKey || e.button === 1) {
      window.open(`/`, "_blank");
    } else if (e.type === "click") {
      setIsMenuActive(false);
      navigate(`/`);
    }
  };

  return (
    <>
      <Logo onClick={(e) => goHome(e)} />
      <Head>
        <Burger onClick={toogleMenu} className={showMenu ? "invert" : ""}>
          <Lottie
            options={defaultOptions}
            height={37}
            width={37}
            isPaused={isPaused}
            speed={speed}
          />
        </Burger>
        <Offcanvas width={"400px"} className={showMenu ? "show" : ""}>
          <Nav>
            <Link
              className="menuLink"
              onClick={() => setIsMenuActive(false)}
              to="/"
            >
              Home
            </Link>
            <Link
              className="menuLink"
              onClick={() => setIsMenuActive(false)}
              to="/filmes"
            >
              Filmes
            </Link>
            <Link
              className="menuLink"
              onClick={() => setIsMenuActive(false)}
              to="/historia"
            >
              História
            </Link>
          </Nav>
        </Offcanvas>
      </Head>
    </>
  );
};

export default Header;

import styled from "styled-components";
import whiteSquare from "../../assets/images/white-square.svg";

export const MainWrapper = styled.main`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  padding-bottom: 40px;
`;

export const ContentWrapper = styled.section`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin-top: calc(100vw / 16 * 10);

  @media only screen and (max-width: 1200px) {
    max-width: 90%;
    &.mobile {
      max-width: unset;
      padding: 0px 37px;
      margin-top: calc(100vw / 9 * 17);
    }
  }
`;

export const Title = styled.h1`
  display: flex;
  font: ${(props) => props.theme.font.titleHistoria};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
`;

export const Infos = styled.section`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    &.mobile {
      position: relative;
      flex-flow: column wrap;
    }
  }
`;

export const ImagePage = styled.img`
  width: 100%;
  margin: 30px 0px;
  @media only screen and (max-width: 1200px) {
  }
`;

export const ItemWrapper = styled.article`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 30px 0px;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  @media only screen and (max-width: 1200px) {
    &.mobile {
      flex-flow: column wrap;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const Cover = styled.img`
  width: 49%;
  height: 49%;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

export const HistoryWrapper = styled.div`
  width: 49%;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${(props) => props.theme.color.quaternary};
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
  }
`;

export const HistoryTitle = styled.h1`
  display: flex;
  width: 100%;
  font: ${(props) => props.theme.font.titulo};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const HistorySubtitle = styled.h2`
  display: flex;
  width: 100%;
  font: ${(props) => props.theme.font.subtitulo};
  color: ${(props) => props.theme.color.secondary};
  text-transform: uppercase;
  margin-bottom: 20px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.2rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
`;

export const HistoryText = styled.p`
  width: 100%;
  font: ${(props) => props.theme.font.subtitulo};
  color: ${(props) => props.theme.color.secondary};
`;

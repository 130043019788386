import useContextMenu from "../../hooks/useContextMenu";
import { MenuItem, MenuWrapper } from "./styled";

const ContextMenu = () => {
  const { anchorPoint, show, link } = useContextMenu();

  if (show) {
    return link ? (
      <MenuWrapper style={{ top: anchorPoint.y, left: anchorPoint.x }}>
        <MenuItem onClick={() => window.open(link, "_blank")}>
          Abrir link em uma nova guia
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open("/", "_blank");
          }}
        >
          Paramount Pictures Brasil
        </MenuItem>
      </MenuWrapper>
    ) : null;
  }
  return <></>;
};

export default ContextMenu;

import axios from "axios";

const api = axios.create({
  baseURL: "https://paramountpictures.com.br/api",
});

export const getHomeContent = async () => {
  const filmes = await api.get(
    "/home?fields=title,subtitle,friendly_url,banner_desktop,banner_mobile,cover,release_date,out_date,youtube_id",
    {
      timeout: 10000,
    }
  );
  return filmes;
};

export const getFilmesEmBreve = async () => {
  const filmes = await api.get("/coming_soon", {
    timeout: 10000,
  });
  return filmes;
};

export const getFilmesEmCartaz = async () => {
  const filmes = await api.get("/in_theaters", {
    timeout: 10000,
  });
  return filmes;
};

export const getFilmesFora = async (page = 1) => {
  const filmes = await api.get(`/movies?page=${page}`, {
    timeout: 10000,
  });
  return filmes;
};

export const getFilme = async (urlAmigavel) => {
  const filme = await api.get(`/movie/${urlAmigavel}`, {
    timeout: 10000,
  });
  return filme;
};

export const getHistoria = async () => {
  const historia = await api.get("/history", {
    timeout: 10000,
  });
  return historia;
};

import { styled } from "styled-components";
import paramountLogo from "../../assets/images/logo-paramount.svg";

export const Foot = styled.footer`
  pointer-events: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 60px 30px;
  margin-top: auto;
  background-color: ${({ theme }) => theme.color.secondary};
  @media only screen and (max-width: 1200px) {
    padding: 30px 30px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  /* @media only screen and (max-width: 1200px) {
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  } */
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;
  height: 100%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const Socials = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 44px;
  margin-bottom: 50px;

  @media only screen and (max-width: 1200px) {
    align-items: center;
    height: calc(193px / 2);
    margin-bottom: 0px;
  }
`;

export const Social = styled.a`
  pointer-events: all;
  cursor: pointer;
  width: 44px;
  height: 44px;
  margin-left: 0px;
  margin-right: 30px;
  text-decoration: none;
  transition: all 0.2s ease-out;

  &:hover {
    filter: brightness(3);
  }
  @media only screen and (max-width: 1200px) {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const Logo = styled.button`
  pointer-events: all;
  cursor: pointer;
  width: 240px;
  height: 193px;
  flex-shrink: 0;
  background-image: url(${paramountLogo});
  background-repeat: no-repeat;
  margin-left: 30px;
  //   filter: grayscale(100%) invert();
  @media only screen and (max-width: 1200px) {
    position: absolute;
    right: 0px;
    width: calc(240px / 2);
    height: calc(193px / 2);
  }
`;

export const LinksLegais = styled.div`
  pointer-events: all;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 30px;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-flow: column wrap;
    /* text-align: center; */
  }
`;

export const LinkLegal = styled.a`
  text-decoration: none;
  font: ${(props) => props.theme.font.textoCorrido};
  color: ${(props) => props.theme.color.primary};
  z-index: 3000001;
  margin-bottom: 10px;

  &:hover {
    color: ${(props) => props.theme.color.quartenary};
  }

  /* &:not(:first-child):before {
    content: " ";
    white-space: pre;
  } */
  &:before {
    content: " ";
    white-space: pre;
  }
  &:not(:last-child):after {
    content: " |";
  }
  @media only screen and (max-width: 1200px) {
    margin-bottom: 10px;
    font: ${(props) => props.theme.font.textoCorridoMobile};

    &:not(:last-child):after {
      content: "";
    }
  }
`;

export const TextoLegal = styled.p`
  font: ${(props) => props.theme.font.textoCorrido};
  color: ${(props) => props.theme.color.primary};
  width: 100%;
  text-align: left;
  @media only screen and (max-width: 1200px) {
    text-align: center;
    font: ${(props) => props.theme.font.rodape};
  }
`;

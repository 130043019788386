import styled from "styled-components";

export const BannersWrapper = styled.section`
  position: relative;
  pointer-events: all;
  height: 56.25vw; //16:9 aspect ratio
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 0;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  div {
    color: white;
  }

  @media only screen and (max-width: 1200px) {
    &.mobile {
      height: 150vw; //9:16 aspect ratio
    }
  }
`;

export const BannerMenu = styled.ul`
  position: absolute;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  opacity: 1;
  transition: opacity 0.5s ease-out 0.5s;

  &.off {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

  @media only screen and (max-width: 1200px) {
    &.mobile {
    }
  }
`;

export const BannerMenuPoint = styled.li`
  cursor: pointer;
  display: block;
  pointer-events: all;
  text-decoration: none;
  font: "Arial";
  color: ${(props) => props.theme.color.tertiary};
  font-weight: bolder;
  font-size: 2rem;
  margin: 0 5px;
  transform-origin: 60% 60%;
  transition: all 0.4s ease-out;

  &.selected {
    color: ${(props) => props.theme.color.primary};
    filter: brightness(10);
    transform: scale(1.5);
  }

  &:after {
    content: "•";
  }

  &:hover {
    color: ${(props) => props.theme.color.primary};
    filter: brightness(10);
  }
`;

import styled from "styled-components";

export const MainWrapper = styled.main`
  width: 100vw;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 180px 0px 20px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      padding: 120px 0px 20px;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`;

import { Fragment, useEffect, useRef, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Header from "../../components/Header";
import { getHistoria } from "../../services/api";
import Footer from "../../components/Footer";
import {
  ContentWrapper,
  Cover,
  Title,
  Infos,
  MainWrapper,
  HistorySubtitle,
  HistoryTitle,
  HistoryWrapper,
  ImagePage,
  ItemWrapper,
  HistoryText,
} from "./styled";
import { BrowserView, isMobile } from "react-device-detect";

const Historia = ({ setIsMenuActive }) => {
  const historiaRef = useRef(false);
  const [historia, setHistoria] = useState(null);

  useEffect(() => {
    if (historia && !historiaRef.current) {
      historiaRef.current = true;
      console.log("historia loaded:", historia);
    }
  }, [historia]);

  useEffect(() => {
    getHistoria().then((res) => {
      setHistoria(res.data);
    });
  }, []);

  return (
    <>
      {historia && (
        <>
          <Header setIsMenuActive={setIsMenuActive} />

          <MainWrapper
            style={{
              backgroundImage: `url(${
                isMobile
                  ? historia.header_background_mobile
                  : historia.header_background_desktop
              })`,
            }}
            className={isMobile ? "mobile" : ""}
          >
            <ContentWrapper className={isMobile ? "mobile" : ""}>
              <Title className={isMobile ? "mobile" : ""}>
                História da Paramount
              </Title>
              <Infos className={isMobile ? "mobile" : ""}>
                <ImagePage
                  src={historia.image_page}
                  alt={"Imagem Topo História Paramount"}
                  className={isMobile ? "mobile" : ""}
                />
                {historia.data.map((item, index) => (
                  <ItemWrapper key={index} className={isMobile ? "mobile" : ""}>
                    <Cover
                      src={item.image}
                      alt={`${item.year} - ${item.title}`}
                      className={isMobile ? "mobile" : ""}
                    />
                    <HistoryWrapper className={isMobile ? "mobile" : ""}>
                      <HistoryTitle className={isMobile ? "mobile" : ""}>
                        {`${item.year} - ${item.year_end}`}
                      </HistoryTitle>
                      <HistorySubtitle className={isMobile ? "mobile" : ""}>
                        {item.title}
                      </HistorySubtitle>
                      <HistoryText
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                    </HistoryWrapper>
                  </ItemWrapper>
                ))}
              </Infos>
            </ContentWrapper>
          </MainWrapper>
          <Footer />
        </>
      )}
    </>
  );
};

export default Historia;

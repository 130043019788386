import { useEffect, useRef, useState } from "react";
import {
  ListMoviesWrapper,
  ListMoviesItemWrapper,
  ListMoviesItemPoster,
  ListMoviesInfos,
  ListMoviesTitle,
  ListMoviesSubtitle,
  ListMoviesWrapperTitle,
  ListMoviesDate,
} from "./styled";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import ContextMenu from "../ContextMenu";

const ListMoviesItem = ({
  title,
  subtitle,
  friendly_url,
  banner_desktop,
  banner_cover,
  release_date,
  out_date,
  youtube_id,
  cover,
  wrapperTitle,
}) => {
  const navigate = useNavigate();

  const goFilm = (e, friendly_url) => {
    e.preventDefault();
    if (e.ctrlKey || e.button === 1) {
      window.open(`/filmes/${friendly_url}`, "_blank");
    } else if (e.type === "click") {
      navigate(`/filmes/${friendly_url}`);
    }
  };
  const goFilmContext = (e, friendly_url) => {
    window.open(`/filmes/${friendly_url}`, "_blank");
  };
  return (
    <>
      <ContextMenu />

      <ListMoviesItemWrapper
        data-link={`/filmes/${friendly_url}`}
        onClick={(e) => goFilm(e, friendly_url)}
        // onContextMenu={(e) => goFilmContext(e, friendly_url)}
      >
        <ListMoviesItemPoster>
          <img src={cover} alt={`Capa ${title}`} />
        </ListMoviesItemPoster>
        <ListMoviesInfos>
          <ListMoviesTitle>{title}</ListMoviesTitle>
          <ListMoviesSubtitle>{subtitle}</ListMoviesSubtitle>
          {wrapperTitle !== "Outros Filmes" && (
            <ListMoviesDate>
              {new Date(release_date * 1000)
                .toLocaleString()
                .split(" ")[0]
                .replace(",", "")}
            </ListMoviesDate>
          )}
        </ListMoviesInfos>
      </ListMoviesItemWrapper>
    </>
  );
};

const ListMovies = ({ movies, wrapperTitle }) => {
  return (
    <ListMoviesWrapper>
      <ListMoviesWrapperTitle>{wrapperTitle}</ListMoviesWrapperTitle>
      {movies.map((movie, index) => (
        <ListMoviesItem
          key={index}
          title={movie.title}
          subtitle={movie.subtitle}
          friendly_url={movie.friendly_url}
          banner_desktop={movie.banner_desktop}
          banner_mobile={movie.banner_mobile}
          cover={movie.cover}
          release_date={movie.release_date}
          out_date={movie.out_date}
          youtube_id={movie.youtube_id}
          wrapperTitle={wrapperTitle}
        />
      ))}
    </ListMoviesWrapper>
  );
};

export default ListMovies;

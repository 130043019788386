export const dataExtenso = (data_original, data_final) => {
  function isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
  }

  function addOneDay(date = new Date()) {
    date.setDate(date.getDate() + 1);

    return date;
  }

  //data_informada:  dd/mm/aaaa
  const meses = new Array(
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  );
  const semana = new Array(
    "Domingo",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado"
  );
  let data_informada = data_original.toLocaleString("pt-BR").split(",")[0];
  var dia_informado = data_informada.split("/")[0];
  var mes_informado = data_informada.split("/")[1];
  var ano_informado = data_informada.split("/")[2].split(" ")[0];
  var data =
    ano_informado + "-" + mes_informado + "-" + dia_informado + " 00:00:00";

  var dataInfo = new Date(data);

  var dia = dataInfo.getDate();
  var dias = dataInfo.getDay();
  var mes = dataInfo.getMonth();
  var ano = dataInfo.getFullYear();

  var diaext = (
    <>
      {isDateInThisWeek(data_original) && new Date() < data_original
        ? "Quinta-feira nos cinemas"
        : !isDateInThisWeek(data_original) && new Date() < data_original
        ? `${dia_informado} de ${
            meses[parseInt(mes_informado) - 1]
          } de ${ano_informado}`
        : (data_final && new Date() <= addOneDay(data_final)) ||
          data_final === null
        ? "Hoje nos cinemas"
        : ""}
    </>
  );
  return diaext;
};

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Theme from "./theme";
import Overlay from "./components/Overlay";
import { useState } from "react";
import Home from "./pages/Home";
import Filmes from "./pages/Filmes";
import Historia from "./pages/Historia";
import Filme from "./pages/Filme";

function App() {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Home isMenuActive={isMenuActive} setIsMenuActive={setIsMenuActive} />
      ),
    },
    {
      path: "/filmes",
      element: <Filmes setIsMenuActive={setIsMenuActive} />,
    },
    {
      path: "/filmes/:friendly_url",
      element: <Filme setIsMenuActive={setIsMenuActive} />,
    },
    {
      path: "/historia",
      element: <Historia setIsMenuActive={setIsMenuActive} />,
    },
  ]);
  return (
    <ThemeProvider theme={Theme}>
      <Overlay isMenuActive={isMenuActive} opacity={0.8} />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;

import styled from "styled-components";

export const MenuWrapper = styled.ul`
  font-size: 14px;
  background-color: #fff;
  border-radius: 6px;
  padding: 0;
  width: auto;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 0px 0 #ccc;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  z-index: 1000;
`;

export const MenuItem = styled.li`
  pointer-events: all;
  width: 100%;
  padding: 0.5em 1em;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

import { styled } from "styled-components";
import paramountLogo from "../../assets/images/logo-paramount.svg";

export const Head = styled.header`
  pointer-events: none;
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 100vw;
  padding: 20px 30px;
  z-index: 10;
  // background-color: ${({ theme }) => theme.color.primary};
`;

export const Logo = styled.button`
  position: absolute;
  width: 137px;
  height: 110.41px;
  background-image: url(${paramountLogo});
  background-repeat: no-repeat;
  filter: grayscale(100%) invert();
  top: 20px;
  left: 30px;
  z-index: 10;
  @media only screen and (max-width: 1200px) {
    width: 15vw;
    height: 13vw;
  }
`;

export const Burger = styled.button`
  pointer-events: all;
  width: 37px;
  height: 37px;
  margin-right: 20px;
  cursor: pointer;
  z-index: 105;
  filter: invert();
  transition: all 0.6s ease-in-out;
  z-index: 10;

  &.invert {
    filter: none;
  }

  @media only screen and (max-width: 1200px) {
    margin-right: 0px;
  }
`;

export const Offcanvas = styled.div`
  position: fixed;
  display: flex;
  visibility: hidden;
  pointer-events: none;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: 100vh;
  right: ${(props) => `-${props.width}`};
  top: 0;
  z-index: 1;
  background-color: ${(props) => props.theme.color.tertiary};
  transition: right 0.6s ease-in-out 0s, visibility 0.6s ease-in-out 0.6s;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    right: -100%;
  }

  &.show {
    visibility: visible;
    pointer-events: all;
    right: 0%;
    transition: right 0.6s ease-in-out 0s, visibility 0s ease-in-out 0s;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    font: ${(props) => props.theme.font.titulo};
    color: ${(props) => props.theme.color.primary} !important;
    text-decoration: none;
    padding: 10px;
  }
`;

import { useEffect, useRef, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Header from "../../components/Header";
import {
  getFilmesEmBreve,
  getFilmesEmCartaz,
  getFilmesFora,
  getHomeContent,
} from "../../services/api";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";
import ListMovies from "../../components/ListMovies";
import { MainWrapper, PaginationWrapper } from "./styled";
import { isMobile } from "react-device-detect";
import "./index.scss";

const Filmes = ({ setIsMenuActive }) => {
  const [homeContent, setHomeContent] = useState(null);
  const [foraDeCartazFilmes, setForaDeCartazFilmes] = useState(null);
  const homeContentRef = useRef(false);
  const foraDeCartazFilmesRef = useRef(false);

  const loadPage = (page) => {
    getFilmesFora(page).then((res) => {
      setForaDeCartazFilmes(res.data);
    });
  };

  useEffect(() => {
    if (foraDeCartazFilmes && !foraDeCartazFilmesRef.current) {
      foraDeCartazFilmesRef.current = true;
      console.log("fora de cartaz filmes:", foraDeCartazFilmes);
    }
  }, [foraDeCartazFilmes]);

  useEffect(() => {
    if (homeContent && !homeContentRef.current) {
      homeContentRef.current = true;
      console.log("home content", homeContent);
    }
  }, [homeContent]);

  useEffect(() => {
    getHomeContent().then((res) => {
      setHomeContent(res.data);
    });

    //fora de cartaz
    loadPage(1);
  }, []);

  return (
    <>
      <Header setIsMenuActive={setIsMenuActive} />
      <MainWrapper className={isMobile ? "mobile" : ""}>
        {homeContent && (
          <>
            <ListMovies
              movies={homeContent.in_theaters}
              wrapperTitle={"Em Cartaz"}
            />
            <ListMovies
              movies={homeContent.coming_soon}
              wrapperTitle={"Em Breve nos Cinemas"}
            />
          </>
        )}
        {foraDeCartazFilmes && (
          <>
            <ListMovies
              movies={foraDeCartazFilmes.data}
              wrapperTitle={"Outros Filmes"}
            />
            {foraDeCartazFilmes.last_page > 1 && (
              <PaginationWrapper>
                <Pagination
                  count={foraDeCartazFilmes.last_page}
                  variant="outlined"
                  color="primary"
                  onChange={(e, page) => loadPage(page)}
                />
              </PaginationWrapper>
            )}
          </>
        )}
      </MainWrapper>
      <Footer />
    </>
  );
};

export default Filmes;

import { dataExtenso } from "../../../utils/functions";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  BannerAdditional,
  BannerDate,
  BannerGradient,
  BannerInfos,
  BannerItemWrapper,
  BannerSubtitle,
  BannerTitle,
  BannersWrapper,
  ButtonAssistaAoTrailer,
  ButtonSaibaMais,
  ColumnLeft,
  ColumnRight,
  TwoColumnsWrapper,
} from "./styled";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

const BannerItem = ({
  title,
  subtitle,
  friendly_url,
  banner_desktop,
  banner_mobile,
  release_date,
  out_date,
  youtube_id,
}) => {
  const navigate = useNavigate();
  const [dayLaunch, setDayLaunch] = useState(
    dataExtenso(
      new Date(release_date * 1000),
      out_date ? new Date(out_date * 1000) : null
    )
  );

  useEffect(() => {
    // console.log(dayLaunch.props.children);
  }, [dayLaunch]);

  return (
    <>
      <BrowserView>
        <BannerItemWrapper>
          <BannerInfos>
            <BannerTitle>{title}</BannerTitle>
            <BannerSubtitle>{subtitle}</BannerSubtitle>
            <TwoColumnsWrapper>
              <ColumnLeft>
                <BannerDate>{dayLaunch.props.children}</BannerDate>
                {dayLaunch &&
                  dayLaunch.props.children !== "Hoje nos cinemas" && (
                    <BannerAdditional>Somente nos cinemas</BannerAdditional>
                  )}
              </ColumnLeft>
              <ColumnRight>
                <ButtonSaibaMais
                  data-link={`/filmes/${friendly_url}`}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log(e.button);
                    if (e.ctrlKey || e.button === 1) {
                      window.open(`/filmes/${friendly_url}`, "_blank");
                    } else if (e.type === "click") {
                      navigate(`/filmes/${friendly_url}`);
                    }
                  }}
                >
                  Saiba mais
                </ButtonSaibaMais>
                <ButtonAssistaAoTrailer
                  data-link={`/filmes/${friendly_url}?anchor=player`}
                  onClick={(e) => {
                    e.preventDefault();
                    console.log(e.button);
                    if (e.ctrlKey || e.button === 1) {
                      window.open(
                        `/filmes/${friendly_url}?anchor=player`,
                        "_blank"
                      );
                    } else if (e.type === "click") {
                      navigate(`/filmes/${friendly_url}?anchor=player`);
                    }
                  }}
                >
                  Assista ao trailer
                </ButtonAssistaAoTrailer>
              </ColumnRight>
            </TwoColumnsWrapper>
          </BannerInfos>
          <BannerGradient />
          <img src={banner_desktop} alt={`Banner ${title}`} />
        </BannerItemWrapper>
      </BrowserView>
      <MobileView>
        <BannerItemWrapper>
          <BannerInfos>
            <BannerTitle>{title}</BannerTitle>
            <BannerSubtitle>{subtitle}</BannerSubtitle>
            <TwoColumnsWrapper>
              <ColumnLeft>
                <BannerDate>{dayLaunch.props.children}</BannerDate>
                {dayLaunch &&
                  dayLaunch.props.children !== "Hoje nos cinemas" && (
                    <BannerAdditional>Somente nos cinemas</BannerAdditional>
                  )}
              </ColumnLeft>
              <ColumnRight>
                <ButtonSaibaMais
                  data-link={`/filmes/${friendly_url}`}
                  onClick={() => navigate(`/filmes/${friendly_url}`)}
                >
                  Saiba mais
                </ButtonSaibaMais>
                <ButtonAssistaAoTrailer
                  data-link={`/filmes/${friendly_url}?anchor=player`}
                  onClick={() =>
                    navigate(`/filmes/${friendly_url}?anchor=player`)
                  }
                >
                  Assista ao trailer
                </ButtonAssistaAoTrailer>
              </ColumnRight>
            </TwoColumnsWrapper>
          </BannerInfos>
          <BannerGradient className={"mobile"} />
          <img src={banner_mobile} alt={`Banner ${title}`} />
        </BannerItemWrapper>
      </MobileView>
    </>
  );
};

export default BannerItem;

import { useEffect, useRef, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Header from "../../components/Header";
import { getFilme } from "../../services/api";
import Footer from "../../components/Footer";
import {
  ContentWrapper,
  Cover,
  GretaAppBanner,
  Infos,
  MainWrapper,
  PlayerWrapper,
  Subtitle,
  SynopsisNotice,
  SynopsisSubtitle,
  SynopsisTitle,
  SynopsisWrapper,
  TechnicalSheet,
  Title,
  Video,
  Videos,
  VideosGallery,
  VideosTitle,
  WrapperMobileTitle,
} from "./styled";
import { BrowserView, isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";
import gretaAppBannerImg from "../../assets/images/gretaapp-banner-paramount.svg";

const Filme = ({ setIsMenuActive }) => {
  let { friendly_url } = useParams();
  const params = new URLSearchParams(window.location.search);
  const anchor = params.get("anchor") || null;
  const filmeContentRef = useRef(false);
  const [filme, setFilme] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    if (filme && !filmeContentRef.current) {
      filmeContentRef.current = true;
      console.log("filme loaded:", filme);
    }
  }, [filme]);

  useEffect(() => {
    getFilme(friendly_url).then((res) => {
      setFilme(res.data);
    });
    if (anchor) {
      setTimeout(() => {
        var getMeTo = document.getElementById(anchor);
        getMeTo?.scrollIntoView({ behavior: "smooth", block: "center" });
        setAutoPlay(true);
      }, 1000);
    }
  }, []);

  return (
    <>
      {filme && (
        <>
          <Header setIsMenuActive={setIsMenuActive} />
          <MainWrapper
            className={isMobile ? "mobile" : ""}
            style={{
              minHeight: "100vh",
              backgroundImage: isMobile
                ? `linear-gradient(180deg, rgba(0, 0, 0, 1) 0vh, rgba(0, 0, 0, 0) 40vh),
              linear-gradient(180deg, rgba(0, 0, 0, 0) 60vh, rgba(0, 0, 0, 1) 100vh, rgba(0, 0, 0, 0) 101vh), url(${filme.banner_mobile})`
                : `linear-gradient(180deg, rgba(0, 0, 0, 1) 0vh, rgba(0, 0, 0, 0) 40vh),
              linear-gradient(180deg, rgba(0, 0, 0, 0) calc(100vw * 9 / 16 / 2.4), rgba(0, 0, 0, 1) calc(100vw * 9 / 16), rgba(0, 0, 0, 0) 101vh), url(${filme.banner_desktop})`,
              backgroundColor: filme.background_color,
            }}
          >
            <ContentWrapper className={isMobile ? "mobile" : ""}>
              {isMobile && (
                <>
                  <WrapperMobileTitle>
                    <Title className={isMobile ? "mobile" : ""}>
                      {filme.title}
                    </Title>
                    <Subtitle className={isMobile ? "mobile" : ""}>
                      {filme.subtitle}
                    </Subtitle>
                  </WrapperMobileTitle>
                </>
              )}
              {!isMobile && (
                <>
                  <Title className={isMobile ? "mobile" : ""}>
                    {filme.title}
                  </Title>
                  <Subtitle className={isMobile ? "mobile" : ""}>
                    {filme.subtitle}
                  </Subtitle>
                </>
              )}
              <PlayerWrapper className={isMobile ? "mobile" : ""}>
                <ReactPlayer
                  playing={autoPlay}
                  id="player"
                  className={isMobile ? "mobile player" : "player"}
                  url={`https://www.youtube-nocookie.com/embed/${filme.youtube}`}
                  width="100%"
                  height="100%"
                  controls
                />
              </PlayerWrapper>
              <Divider
                width={"100%"}
                style={{
                  margin: "50px 0px",
                  borderColor: "rgba(255,255,255,1)",
                }}
                className={isMobile ? "mobile" : ""}
              />
              <Infos className={isMobile ? "mobile" : ""}>
                <Cover src={filme.cover} className={isMobile ? "mobile" : ""} />
                <SynopsisWrapper className={isMobile ? "mobile" : ""}>
                  <SynopsisTitle className={isMobile ? "mobile" : ""}>
                    {filme.title}
                  </SynopsisTitle>
                  <SynopsisSubtitle className={isMobile ? "mobile" : ""}>
                    {filme.subtitle}
                  </SynopsisSubtitle>
                  {filme.synopsis}
                  <Divider
                    width={"100%"}
                    style={{
                      margin: "50px 0px",
                      borderColor: "rgba(255,255,255,1)",
                    }}
                    className={isMobile ? "mobile" : ""}
                  />
                  <SynopsisNotice>
                    ESTE FILME CONTÉM OS 3 RECURSOS DE ACESSIBILIDADE EM
                    CUMPRIMENTO A LEI N 13.146/2015: AUDIODESCRIÇÃO, LEGENDA
                    DESCRITIVA E LIBRAS. CONSULTE A DISPONIBILIDADE EM SEU
                    CINEMA FAVORITO.
                  </SynopsisNotice>
                  <a
                    style={{ display: "block", width: "100%" }}
                    href="https://www.gretaundstarks.de/starks/GretaEStarks"
                    target="_blank"
                  >
                    <GretaAppBanner width={"100%"} src={gretaAppBannerImg} />
                  </a>
                </SynopsisWrapper>
                <TechnicalSheet
                  className={isMobile ? "mobile" : ""}
                  dangerouslySetInnerHTML={{ __html: filme.technical_sheet }}
                />
              </Infos>
              <Divider
                width={"100%"}
                style={{
                  margin: "50px 0px",
                  borderColor: "rgba(255,255,255,1)",
                }}
                className={isMobile ? "mobile" : ""}
              />
              {filme.others_videos.length > 0 && (
                <Videos className={isMobile ? "mobile" : ""}>
                  <VideosTitle className={isMobile ? "mobile" : ""}>
                    Vídeos
                  </VideosTitle>
                  <VideosGallery className={isMobile ? "mobile" : ""}>
                    {filme.others_videos &&
                      filme.others_videos.map((vid, index) => (
                        <Video
                          key={index}
                          target="new"
                          href={`https://www.youtube-nocookie.com/embed/${vid.youtube}`}
                          style={{ backgroundImage: `url(${vid.thumb})` }}
                          className={isMobile ? "mobile" : ""}
                        >
                          <YouTubeIcon className="youtubeIcon" />
                        </Video>
                      ))}
                  </VideosGallery>
                </Videos>
              )}
            </ContentWrapper>
          </MainWrapper>
          <Footer />
        </>
      )}
    </>
  );
};

export default Filme;

const Theme = {
  color: {
    primary: "#000A3C",
    secondary: "#D9DAE2",
    tertiary: "#ffffff",
    quaternary: "#9D9D9D",
  },

  font: {
    dangerous: "Peak Sans Regular",
    rodape: "0.6rem Peak Sans Regular",
    textoCorrido: "1rem Peak Sans Regular",
    textoCorridoMobile: ".8rem Peak Sans Regular",
    titulo: "2rem Peak Sans Black",
    supertitulo: "3rem Peak Sans Bold",
    tituloBanner: "4vw Peak Sans Black",
    subtituloBanner: "3vw Peak Sans Regular",
    dateBanner: "1.5vw Peak Sans Bold",
    dateBannerSpan: "1.5vw Peak Sans Regular",
    additionalBanner: "1.2vw Peak Sans Regular",
    tituloThumb: "1rem Peak Sans Black",
    subtituloThumb: ".9rem Peak Sans Regular",
    botaoBanner: "1rem Peak Sans Regular",
    titleHistoria: "1.5vw Peak Sans Black",
  },
};

export default Theme;

import styled from "styled-components";

export const MainWrapper = styled.main`
  width: 100vw;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 0px 20px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      padding: 20px 0px 20px;
    }
  }
`;

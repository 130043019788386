import styled from "styled-components";

export const BlackScreen = styled.div`
  pointer-events: none;
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.5s ease-out;

  &.on {
    pointer-events: all;
    background-color: rgba(0, 0, 0, ${(props) => props.opacity});
  }
`;

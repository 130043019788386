import styled from "styled-components";
import whiteSquare from "../../assets/images/white-square.svg";

export const MainWrapper = styled.main`
  width: 100vw;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 30px 0px 20px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      padding: calc(100vw / 9 * 16 / 1.25) 0px 20px;
    }
  }
`;

export const ContentWrapper = styled.section`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;

  @media only screen and (max-width: 1200px) {
    max-width: 90%;
    &.mobile {
      max-width: unset;
      padding: 0px 37px;
    }
  }
`;

export const WrapperMobileTitle = styled.div`
  position: absolute;
  top: 75px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  display: flex;
  font: ${(props) => props.theme.font.dateBanner};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
`;

export const Subtitle = styled.h1`
  display: flex;
  font: ${(props) => props.theme.font.dateBannerSpan};
  font-size: 1.2vw;
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

export const PlayerWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 57%;
  margin-top: calc(100vw / 16 * 9 / 1.7);

  .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1200px) {
      &.mobile {
        position: relative;
        top: unset;
        left: unset;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    &.mobile {
      position: relative;
      padding-top: unset;
      height: calc(100vw / 16 * 9);
      margin-top: calc(100vw / 9 * 16 / 20);
    }
  }
`;

export const Infos = styled.article`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    &.mobile {
      position: relative;
      flex-flow: column wrap;
    }
  }
`;

export const Cover = styled.img`
  width: 30%;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

export const SynopsisWrapper = styled.div`
  width: 45%;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${(props) => props.theme.color.quaternary};
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

export const SynopsisTitle = styled.h1`
  display: flex;
  width: 100%;
  font: ${(props) => props.theme.font.titulo};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const SynopsisSubtitle = styled.h2`
  display: flex;
  width: 100%;
  font: ${(props) => props.theme.font.titulo};
  color: ${(props) => props.theme.color.secondary};
  text-transform: uppercase;
  margin-bottom: 20px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
`;

export const SynopsisNotice = styled.p`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.color.secondary};
  text-transform: uppercase;
  margin-bottom: 60px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
`;

export const TechnicalSheet = styled.div`
  width: 20%;
  color: ${(props) => props.theme.color.quaternary};
  strong {
    color: ${(props) => props.theme.color.tertiary};
  }
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
    }
  }
`;

export const GretaAppBanner = styled.img`
  object-fit: fill;
  margin-bottom: 20px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
  }
`;

export const Videos = styled.section`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const VideosTitle = styled.h1`
  display: flex;
  width: 100%;
  font: ${(props) => props.theme.font.titulo};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
`;

export const VideosGallery = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 0px 50px;
  @media only screen and (max-width: 1200px) {
    &.mobile {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Video = styled.a`
  width: calc(458px * 0.82);
  height: calc(258px * 0.82);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: azure;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 15px;
  margin-right: 15px;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s ease-out;
  outline: solid 1px rgba(255, 255, 255, 0.5);
  outline-offset: 0px;

  &:hover {
    transform: scale(1.05);
    opacity: 0.7;
  }

  .youtubeIcon {
    width: calc(58px * 2);
    height: calc(41px * 2);
    color: red;
    background-image: url(${whiteSquare});
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media only screen and (max-width: 1200px) {
    &.mobile {
      margin-right: 7.5px;
      margin-left: 7.5px;
    }
  }
`;

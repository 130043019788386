import styled from "styled-components";

export const BannerItemWrapper = styled.figure`
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: end;
  width: 100vw;
  height: 100%;
  scroll-snap-align: center;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
`;

export const BannerInfos = styled.div`
  z-index: 10;
  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: end;
  width: 100%;
  max-width: 1200px;
  padding: 0px 40px 10vw;

  @media only screen and (max-width: 1200px) {
  }
`;

export const BannerTitle = styled.h1`
  font: ${(props) => props.theme.font.tituloBanner};
  color: ${(props) => props.theme.color.tertiary};
  line-height: 3vw;
  text-transform: uppercase;
  @media only screen and (max-width: 1200px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;
export const BannerSubtitle = styled.h2`
  font: ${(props) => props.theme.font.subtituloBanner};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  @media only screen and (max-width: 1200px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const TwoColumnsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 2vw;
  @media only screen and (max-width: 1200px) {
    flex-flow: column wrap;
  }
`;

export const ColumnLeft = styled.div`
  width: 60%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const BannerDate = styled.h3`
  font: ${(props) => props.theme.font.dateBanner};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-bottom: 0vw;
  span {
    font: ${(props) => props.theme.font.dateBannerSpan};
    @media only screen and (max-width: 1200px) {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 1200px) {
    font-size: 1rem;
  }
`;

export const BannerAdditional = styled.h4`
  font: ${(props) => props.theme.font.additionalBanner};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  margin-top: 0vw;
  @media only screen and (max-width: 1200px) {
    font-size: 0.8rem;
  }
`;

export const ColumnRight = styled.div`
  width: 40%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    justify-content: flex-start;
    margin-top: 20px;
  }
`;

export const ButtonSaibaMais = styled.button`
  pointer-events: all;
  cursor: pointer;
  width: 12vw;
  height: 3.4vw;
  border: none;
  background-color: ${(props) => props.theme.color.tertiary};
  font: ${(props) => props.theme.font.botaoBanner};
  color: ${(props) => props.theme.color.primary};
  text-transform: uppercase;
  margin-right: 15px;
  border-radius: 0.7vw;
  user-select: none;
  transform: scale(1);
  transition: all 0.2s ease-out 0s;
  transform-origin: center center;

  &:hover {
    border: 0px solid ${(props) => props.theme.color.tertiary};
    background-color: ${(props) => props.theme.color.tertiary};
    color: ${(props) => props.theme.color.primary};
    transform: scale(1.05);
  }

  @media only screen and (max-width: 1200px) {
    width: 160px;
    height: 40px;
    font-size: 0.8rem;
  }
`;

export const ButtonAssistaAoTrailer = styled.button`
  pointer-events: all;
  cursor: pointer;
  width: 12vw;
  height: 3.4vw;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.color.tertiary};
  font: ${(props) => props.theme.font.botaoBanner};
  color: ${(props) => props.theme.color.tertiary};
  text-transform: uppercase;
  border-radius: 0.7vw;
  user-select: none;
  transform: scale(1);
  transition: all 0.2s ease-out 0s;
  transform-origin: center center;

  &:hover {
    border: 0px solid ${(props) => props.theme.color.tertiary};
    background-color: ${(props) => props.theme.color.tertiary};
    color: ${(props) => props.theme.color.primary};
    transform: scale(1.05);
  }
  @media only screen and (max-width: 1200px) {
    width: 160px;
    height: 40px;
    font-size: 0.8rem;
  }
`;

export const BannerGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 56.25vw;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%),
    linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);
  &.mobile {
    height: 100%;
  }
`;
